import { FC } from 'react';
import dynamic from 'next/dynamic';
import { HStack } from '@chakra-ui/react';
import { Post } from '@/types';
import Fields, { resolveFields } from '@/components/frontend/Fields';
import ArticleLayout, {
  FieldsLayout,
} from '@/components/frontend/ArticleLayout';
import Hero from '@/components/frontend/Hero';
import { splitFields } from './Page';
import ContentSection from '../frontend/ContentSection';
import { useIsTemplate } from '@/lib/hooks/useApp';

const Buttons = dynamic(() => import('@/components/frontend/Buttons'));

const Frontpage: FC<Post> = ({
  fields,
  post_title: title,
  post_excerpt: leadText,
  featured_image,
  children,
}) => {
  const [hero, contentFields] = resolveFields(fields);
  const { basicFields, sectionFields } = splitFields(contentFields);
  const isCampaignPage = useIsTemplate('campaign-listings-page');

  const { buttons, ...heroProps } = hero
    ? {
        title: hero.heading || title,
        leadText: hero.text || leadText,
        image: hero.image || featured_image,
        buttons: hero.buttons || null,
      }
    : { title, leadText, image: featured_image, buttons: null };

  return (
    <ArticleLayout>
      <Hero {...heroProps} variant="landing">
        {buttons?.length ? (
          <Buttons
            buttons={buttons}
            {...(buttons.length > 1 ? { as: HStack, flexWrap: 'wrap' } : null)}
          />
        ) : null}
      </Hero>
      <FieldsLayout sectionsLayout>
        {children}
        <Fields fields={sectionFields} />
        {isCampaignPage && basicFields?.length ? (
          /* We could use Box here with 84ch maxWidth and mx="auto". But the issue is WP and content input, which we cant fully control.
           99% sure it would work, but not 100% */
          <ContentSection
            display={[null, null, null, null, 'grid']}
            maxW={['84ch', null, null, null, '100%']}
          >
            <Fields fields={basicFields} />
          </ContentSection>
        ) : null}
      </FieldsLayout>
    </ArticleLayout>
  );
};

export default Frontpage;
